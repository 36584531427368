<template>
  <div class="organisation-selector">
    <!-- Not many orgs selector -->
    <div v-if="notManyOrgs" class="not-many-orgs">
      <!-- Org -->
      <div
        v-for="(org, i) in organisationsIAmAnAdminFor"
        :key="i"
        class="org-button"
        @click.prevent="() => selectOrganisation(org)"
        :class="{ selected: isOrganisationSelected(org) }"
      >
        <organisation-thumbnail
          :tenant-id="getOrgTenantId(org)"
          :org-id="org.id"
          class="org-thumbnail"
        ></organisation-thumbnail>
        {{ getOrganisationDisplayName(org) }}
      </div>
      <!-- / Org -->
    </div>
    <!-- / Not many orgs selector -->

    <!-- Lots of orgs -->
    <div v-if="!notManyOrgs" class="many-orgs">
      <div class="org-dropdown">
        <a-select
          size="large"
          style="width: 350px"
          option-filter-prop="children"
          show-search
          :filter-option="filterOption"
          v-model="selected_organisation"
        >
          <a-select-option
            v-for="(org, i) in organisationsIAmAnAdminFor"
            :value="org.id"
            :label="getOrganisationDisplayName(org)"
            :key="i"
          >
            <span role="img">
              <organisation-thumbnail
                :tenant-id="getOrgTenantId(org)"
                :org-id="org.id"
                style="display: inline; margin-right: 13px"
                size="small"
                :key="selectedOrganisation ? selectedOrganisation.id : 100"
              ></organisation-thumbnail>
            </span>

            {{ getOrganisationDisplayName(org) }}
          </a-select-option>
        </a-select>
      </div>

      <!-- Recent orgs -->
      <div class="recent-orgs">
        <!-- Org -->
        <div
          v-for="(org, i) in recentOrgs"
          :key="i"
          class="org-button"
          @click.prevent="() => selectOrganisation(org)"
          :class="{ selected: isOrganisationSelected(org) }"
        >
          <organisation-thumbnail
            :key="org.id"
            :tenant-id="getOrgTenantId(org)"
            :org-id="org.id"
            class="org-thumbnail"
          ></organisation-thumbnail>

          <div class="display-name">
            {{ getOrganisationDisplayName(org) }}
          </div>

          <a-icon class="viewed-icon" type="eye" /> 
        </div>
        <!-- / Org -->
      </div>
      <!-- / Recent orgs -->
    </div>
    <!-- / Lots of orgs -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import OrganisationThumbnail from "../../components/Organisations/OrganisationThumbnail.vue";
import Organisations from "../../mixins/Organisations";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");
export default {
  components: { OrganisationThumbnail },
  mixins: [Organisations],
  computed: {
    ...mapGetters("missionControl", {
      organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor",
    }),
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      recentOrganisations: "recentOrganisations",
    }),
    ...mapGetters("subjectPreferences", {
      lastOrgId: "lastOrgId",
    }),

    recentOrgs() {
      let orgs = [];
      _.each(this.recentOrganisations, (recentOrgId) => {
        let org = _.find(this.organisationsIAmAnAdminFor, { id: recentOrgId });
        if (org) {
          orgs.push(org);
        }
      });
      return orgs;
    },

    notManyOrgs() {
      return this.organisationsIAmAnAdminFor.length < 5;
    },

    selected_organisation: {
      get() {
        return this.selectedOrganisation ? this.selectedOrganisation.id : null;
      },
      set(val) {
        this.selectOrganisation(
          _.find(this.organisationsIAmAnAdminFor, { id: val })
        );
      },
    },
  },
  created() {
    if (this.organisationsIAmAnAdminFor.length && !this.selectedOrganisation) {
      // Attempt to get from subject preferences, otherwise select first org
      if (
        this.lastOrgId &&
        _.map(this.organisationsIAmAnAdminFor, "id").includes(this.lastOrgId)
      ) {
        this.selectOrganisation(
          _.find(this.organisationsIAmAnAdminFor, { id: this.lastOrgId })
        );
      } else {
        this.selectOrganisation(_.first(this.organisationsIAmAnAdminFor));
      }
    }
  },
  methods: {
    ...mapActions("admin", {
      selectOrganisation: "selectOrganisation",
    }),

    isOrganisationSelected(org) {
      return org && this.selectedOrganisation
        ? org.id === this.selectedOrganisation.id
        : false;
    },

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style lang="scss">
.organisation-selector {
  .not-many-orgs,
  .recent-orgs {
    display: flex;
    flex-wrap: wrap;

    .org-thumbnail {
      display: inline;
      margin-right: 10px;
    }

    &.hidden {
      display: none;
    }

    .org-button {
      margin-right: 10px;
      background: #fff;
      border-radius: 6px;
      padding: 18px 20px;
      cursor: pointer;
      margin-bottom: 10px;

      &.selected {
        // background: rgba(81, 85, 234, 0.1);
        color: #5155ea;
        border: 1px solid #5155ea;
      }
    }
  }

  .many-orgs {
    display: flex;
    .org-dropdown {
      flex-shrink: 1;
      display: flex;
      align-items: center;
      padding-right: 10px;

      .ant-select-lg .ant-select-selection--single {
        height: 62px;
        border: 0;
      }

      .ant-select-lg .ant-select-selection__rendered {
        line-height: 59px;
      }
    }
    .recent-orgs {
      flex-grow: 1;
      .org-button {
        margin-bottom: 0;
        padding: 15px 19px;

        display: flex;
        align-items: center;

        .display-name {
          margin-left: 5px;
        }

        .viewed-icon {
          margin-left: 13px;
          font-size: 18px;
          color: #888;
        }
      }
    }
  }
}
</style>